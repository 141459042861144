.card {
        padding: 18px 18px 5px;
        width: 255px;
        margin: 6px;
        text-align: left;
        text-decoration: none;
        color: #434343;
        border: 1px solid #9b9b9b;
}
.card:hover {
        border-color: #067df7;
}
.card h3 {
        margin: 0;
        color: #067df7;
        font-size: 20px;
}
.card h4 {
        margin: 0;
        color: #067df7;
        font-size: 15px;
}
.card h5 {
        color: #067df7;
        font-size: 13px;
        text-align: center;
        margin-bottom: 0;
}
.holdings {
    text-align: center;
    margin: 5px;
}
.description {
        margin: 0;
        padding: 12px 0 0;
        font-size: 13px;
        color: #333;
}

@media (max-width: 700px) {
    .card {
        min-width: 20em;
    }
}