.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 38px;
}

.subsection {
        padding-top: 6px;
        text-align: center;
        margin-bottom: 2em;
        padding: 1em;
}
.subsection p {
        margin: auto;
        max-width: 600px;
}

h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.Home .lander p {
  color: #616161;
}

.box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -5em 0 -0.5em 0;
}
.logo {
        width: 8em;
}
.footer {
          color: #969696;
          font-size: 8px;
          margin: 12px;
          padding-top: 0px;
          text-align: center;
}

@media (max-width: 700px) {
    .subsection {
        padding-top: 6px;
        text-align: center;
        margin-bottom: 4em;
        padding: 1em;
}
}