
.row {
        max-width: 880px;
        margin: -1em auto 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
}
@media (max-width: 700px) {
.row {
        margin: -3em 4em 2.8em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }
}